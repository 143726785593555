'use strict';

const Contact = {
    el: null,
    init: function () {
        Contact.el = document.querySelector('.t-contact');

        if (Contact.el) {
            let form = Contact.el.querySelector("#t-contact__form");
            Contact.url = form.dataset.api_endpoint;

            console.log("URL", Contact.url);

            if (form && Contact.url) {

                form.captcha = form.querySelector("[data-sitekey]");
                form.errors = Contact.el.querySelector(".t-contact__error");
                form.errors.style.display = "none";

                form.addEventListener("submit", e => {
                    e.preventDefault();

                    form.errors.innerHTML = "";
                    form.errors.style.display = "none";
                    form.querySelectorAll("[name]").forEach(field => field.classList.remove("error"))

                    let formData = new FormData(form);

                    fetch(Contact.url,
                        {
                            method: "POST",
                            body: formData
                        }
                    )
                        .then(response => response.json())
                        .then(json => {
                            if (json.data?.status === 200) {
                                Contact.el.classList.add("t-contact--completed");
                            }
                            else if (json.code === "error_validation") {
                                if (form.captcha) {
                                    form.captcha.friendlyChallengeWidget.reset();

                                    for (var err in json.data.errors) {
                                        form.querySelector("[name='" + err + "']").classList.add("error");
                                        form.errors.innerHTML += "-" + json.data.errors[err] + "<br>";
                                    }
                                    form.errors.style.display = "block";
                                }
                            }
                        });
                })

                form.textarea = Contact.el.querySelector("#message")
                form.textareacount = Contact.el.querySelector(".t-contact__count")
                form.count = parseInt(form.textareacount.dataset.max);


                if(form.textarea){
                    form.textarea.addEventListener("keydown", () => {
                        if(form.textarea.value.length > form.count){
                            form.textarea.value = form.textarea.value.substring(0, form.count);
                        }
                        form.textareacount.innerHTML = form.count - form.textarea.value.length;
                    })

                    form.textarea.addEventListener("keyup", () => {
                        if(form.textarea.value.length > form.count){
                            form.textarea.value = form.textarea.value.substring(0, form.count);
                        }
                        form.textareacount.innerHTML = form.count - form.textarea.value.length;
                    })

                    form.textareacount.innerHTML = form.count - form.textarea.value.length;
                }
            }
        }
    }
};

export default Contact;