class Menu{
    static checkLogo() {
        if(Menu.main && Menu.logowhite && Menu.logoblack){
            let mainInfos = Menu.main.getBoundingClientRect();
            let mainTop = mainInfos.top;

            if(mainTop >= 60){
                Menu.logowhite.classList.add("visible");
                Menu.logowhite.classList.remove("hidden");

                Menu.logoblack.classList.remove("visible");
                Menu.logoblack.classList.add("hidden");
            } else {
                Menu.logowhite.classList.remove("visible");
                Menu.logowhite.classList.add("hidden");

                Menu.logoblack.classList.add("visible");
                Menu.logoblack.classList.remove("hidden");
            }
        }
    }

    static init(){
        this.header = document.querySelector(".header");
        this.main = document.querySelector(".main__flexibles");
        this.logowhite = document.querySelector(".header__logo--white");
        this.logoblack = document.querySelector(".header__logo--black");

        this.lastScroll = document.documentElement.scrollTop;
        if(this.header){
            document.addEventListener("scroll", (e) => {
                this.tempScroll = document.documentElement.scrollTop;
                if(this.lastScroll < this.tempScroll){
                    this.header.classList.add("header--hidden");
                } else {
                    this.header.classList.remove("header--hidden");
                }

                this.checkLogo();

                this.lastScroll = this.tempScroll
            })
        }

        Menu.checkLogo();
    }
}

export default Menu;